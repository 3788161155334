import React from "react";
import Card from 'react-bootstrap/Card';

const ArunachalamKariveniSatram:React.FC = () =>{
    return(
        <>
        {/* embeed youtube video: https://youtu.be/A1Wab5BLubQ?si=QKFgMjHE6uyTuptf */}
            <br/><br/>
            <h1>Karivena Satram - Arunachalam</h1>
            <br />
            <Card style={{ width: '18rem' }}>
                <Card.Img variant='top' className="d-block mx-auto img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/karivena-brahmana-satram-arunachalam.jpeg`} />
            </Card>
            <Card>
                <Card.Body>
                    <strong>Accomodation: </strong>
                    <Card.Link href="tel:+91 94873 40111">+919487340111</Card.Link> /
                    <Card.Link href="tel:+91 04175 224060">+9104175224060</Card.Link> <br />
                    <strong>Meals: </strong>
                    <Card.Link href="tel:+91 93907 34802">+919390734802</Card.Link> /
                    <Card.Link href="tel:+91 97048 33439">+919704833439</Card.Link> /
                    <Card.Link href="tel:+91 83339 07813">+918333907813</Card.Link>
                    <br /><br />
                    <Card.Text>
                        <strong> Address: </strong>  HNo 71, S No 118/1a, Tiruvoodal Street, South mada veedhi, Tiruvannamalai, Near Kalyan jewelary, Opp Hindu Nadar satram.  <br />
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default ArunachalamKariveniSatram;