import React from 'react'

const Contact:React.FC = () => {
    return(
        <div>
            <h1>This is the contact page under construction... we are re-building...!</h1>
        </div>
    )
}

export default Contact