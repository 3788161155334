import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

const Home:React.FC =()=>{
    return(
        // <Container fluid>
        <>
            <h1>Namaste</h1>
            <br/>
            <p><strong>Welcome to <Link to="/">Brahmana Seva Samithi</Link></strong></p>
            <p><strong>Discovering Brahmin Organizations Across India</strong></p>
            <p>Welcome to <Link to="/">Brahmana Seva Samithi</Link>, your one-stop destination for exploring a diverse array of Brahmin organizations dedicated to social progress, cultural enrichment, and community welfare. Our platform is designed to connect individuals, promote awareness, and celebrate the initiatives that contribute to the betterment of society.</p>
            <p><strong>Explore Brahmin Initiatives</strong></p>
            <p>At <Link to="/">Brahmana Seva Samithi</Link>, we are committed to showcasing the remarkable work undertaken by Brahmin organizations throughout India. From education and healthcare to cultural preservation and community development, these initiatives play a vital role in shaping a brighter future for our society.</p>
            <p><strong>Connect and Engage</strong></p>
            <p>Are you looking to connect with like-minded individuals who share an interest in Brahmin culture and community welfare? Our platform offers an opportunity to engage with others, exchange ideas, and collaborate on meaningful projects.</p>
            <p><strong>Directory of Organizations</strong></p>
            <p>Navigate our curated directory to discover a wide range of Brahmin organizations that are making a difference. Each organization's profile provides insights into their mission, achievements, and ways to get involved.</p>
            <p><strong>Join the Movement</strong></p>
            <p>Whether you're a member of a Brahmin organization seeking to expand its reach or an individual who wants to stay informed about the latest initiatives, <Link to="/">Brahmana Seva Samithi</Link> invites you to become a part of this movement. Together, we can amplify the positive impact of these organizations and contribute to a more connected and informed society.</p>
            <p><strong>Stay Connected</strong></p>
            <p>Stay up-to-date with the latest news, events, and stories related to Brahmin organizations by subscribing to our newsletter. Follow us on social media to join the conversation and share your insights.</p>
            <p><strong>Get Involved</strong></p>
            <p>Do you know of a Brahmin organization that deserves recognition? Help us build a comprehensive platform by suggesting organizations that should be included in our directory. Your contribution will help us create a more complete and dynamic resource for everyone.</p>
            <p><strong>Contact Us</strong></p>
            <p>For any questions, suggestions, or inquiries, please feel free to reach out to us at <a href="/blog/contact">Contact Page</a>.</p>
            <p>If any of you have a question or you have a different understanding of some of points here, feel free to drop in comments here or via our <Link to="https://www.facebook.com/people/Brahmanasevasamithi/100063582800995/" target="_blank" rel="noopener noreferrer">facebook page</Link>.</p>
            <hr/>           
        {/* // </Container> */}
        </>
    )
}

export default Home