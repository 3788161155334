import { Outlet, Link } from "react-router-dom";
import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { PagesList } from "../types/page";

import './layout.css';

const Layout = () =>{
    return(
        <Container fluid>
            <Navbar expand="lg" className="bg-body-tertiary">
                <Navbar.Brand href="/">Brahmana Seva Samithi</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/about">About</Nav.Link>
                        <Nav.Link href="/annasatram">Anna Satram</Nav.Link>
                        <Nav.Link href={`/list/${PagesList.PATASALA}`}>Patasala</Nav.Link>
                        <Nav.Link href={`/list/${PagesList.ASSOCIATION}`}>Associations</Nav.Link>
                    </Nav>
                    <Nav>
                        <Navbar.Text><a className="nav-link" href="/blog/contact">Contact</a></Navbar.Text>
                        <Navbar.Text><a className="nav-link" href="/blog">Blog</a></Navbar.Text>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Outlet />
        </Container>
    )
}

export default Layout;