import React, { useEffect, useMemo } from 'react';



import ListGroup from 'react-bootstrap/ListGroup';
import { Link, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

import { pageItem, phoneNumbers, PagesList } from '../types/page';

import vedaPatasalaData from "../data/veda_patashala.json";
import associationsData from "../data/associations.json";
import vedaPatasalaImage from "../assets/images/veda-patashala.jpeg";
// https://www.dhiwise.com/post/dynamic-routing-for-building-flexible-and-scalable-react-apps#:~:text=Implementing%20Dynamic%20Routing%20in%20ReactJS,-Introduction%20to%20React&text=It%20provides%20a%20set%20of,seamless%20and%20interactive%20user%20experience.



const ListPage:React.FC = () => {
    const {page_name} = useParams();

    const pageData = useMemo(()=> {
        if(page_name===PagesList.PATASALA){
            return vedaPatasalaData;
        } else if(page_name===PagesList.ASSOCIATION){
            return associationsData;
        }
      }, [page_name]);

    const pageImage = useMemo(()=> {
        if(page_name===PagesList.PATASALA){
            return vedaPatasalaImage;
        }

        //defaulting to some image when no page image is available
        return vedaPatasalaImage;
      }, [page_name]);

      const pageHeading = useMemo(()=> {
        if(page_name===PagesList.PATASALA){
            return "Patasala";
        } else if(page_name===PagesList.ASSOCIATION){
            return "Associations";
        }
      }, [page_name]);

    function getPhone(phone: phoneNumbers){
       const phoneItems =  phone.map((phoneNumber, key)=><div key={"phone_"+key}><a href={"tel:"+phoneNumber}>{phoneNumber}</a><br /></div>)
        return(
            <td>
                {phoneItems}
            </td>
        )
    }

    function getName(item:pageItem){
        const moreDetails = item.more_details_link;
        const name = moreDetails  ? moreDetails.isExternal ? <a href={moreDetails.url} target='_blank'>{item.name}</a> : <a href={moreDetails.url}>{item.name}</a> : <p>{item.name}</p>
        return(
            <td>{ name }</td>
        )
    }

    function getListItem(key:number, item:pageItem){
        return (
            <tr key={"item_"+key}>
                <td>{key+1}</td>
                { getName(item) }
                { getPhone(item.contact.phone) }
                <td>{item.location}</td>
            </tr>
        )
    }

    return(
        <>
            <br/>
            <h1>{pageHeading}</h1>
            <br/>
            <Card style={{ width: '18rem' }}>
                <Card.Img variant='top' className="d-block mx-auto img-fluid w-50" src={pageImage} />
            </Card>
            <br/>
            {/* <h3>కొన్ని బ్రాహ్మణ నిత్యా అన్నా దాన సత్రాల వివరములు</h3> */}
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        pageData && pageData.length>0 && pageData.map((item: pageItem, key:number)=>getListItem(key, item))
                    }
                </tbody>
            </Table>
        </>
    );
}

export default ListPage;
