import React from 'react'
import {  Link } from "react-router-dom";

const About: React.FC = () => {
    return (
        <div>
            <br/><br/>
            <h1>About</h1>
            <br />
            <p><strong>About Us</strong></p>
            <p>Welcome to <Link to="/">Brahmana Seva Samithi</Link>, a platform dedicated to compiling and showcasing various social, cultural, and welfare organizations within the Brahmin community across India. Our mission is to provide a centralized resource for individuals seeking information about these organizations, their objectives, and their contributions to society.</p>
            <p><strong>Our Purpose</strong></p>
            <p>At <Link to="/">Brahmana Seva Samithi</Link>, we believe in fostering unity, promoting positive engagement, and celebrating the rich diversity within the Brahmin community. Our goal is to create a space where individuals can easily find information about Brahmin organizations that are working towards education, cultural preservation, social welfare, and community development.</p>
            <p><strong>What We Do</strong></p>
            <ul>
                <li>
                    <p>
                        <strong>Listing of Organizations:</strong> We curate and maintain a comprehensive directory of Brahmin organizations, offering a platform for these groups to share their missions, activities, and contact information.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Community Connection:</strong> Our platform serves as a hub where individuals interested in Brahmin heritage, culture, and community welfare can connect and collaborate.
                    </p>
                </li>
                <li>
                    <p>
                        <strong>Awareness and Education:</strong> Through our website, we aim to raise awareness about the positive contributions of Brahmin organizations to society, highlighting their efforts in areas such as education, healthcare, and cultural preservation.
                    </p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <p><strong>Join Us</strong></p>
            <p>Whether you're an individual seeking to connect with Brahmin organizations, a member of one of these organizations looking to share your work, or simply interested in learning more about the diverse landscape of Brahmin initiatives, we invite you to explore our website and engage with our content.</p>
            <p><strong>Get Involved</strong></p>
            <p>If you're part of a Brahmin organization not listed on our platform, please don't hesitate to reach out to us. We are constantly working to expand and update our directory, and we welcome your input and contributions.</p>
            <p>Thank you for visiting <Link to="/">Brahmana Seva Samithi</Link>. We are excited about the opportunity to connect individuals and organizations within the Brahmin community and contribute to a more connected and informed society.</p>
            <p>For any inquiries, suggestions, or partnership opportunities, feel free to contact us at <a href="/blog/contact">Contact</a>.</p>
            <p>&nbsp;</p><p>&nbsp;</p>
        </div>
    )
}

export default About