export type phoneNumbers = string[]
export type emailAddress = string[]
export type contactDetails = {
    phone: phoneNumbers,
    email?: emailAddress
}

export type moreDetails = {
    url: string,
    isExternal: boolean
}

export type pageItem = {
    name: string,
    contact: contactDetails,
    location: string;
    more_details_link?: moreDetails
}

export enum PagesList {
    PATASALA = "patasala",
    ASSOCIATION = "association"
}