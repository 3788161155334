import React from 'react';
// import logo from './logo.svg';
import { Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import './App.css';
import AnnaSatram from './pages/AnnaSatram/AnnaSatram';
import VemulawadaRajeshwaraCharitableTrust  from "./DetailPages/vemulawada/SriRajeshwaraCharitableTrust"
import SreeKashiGayatriAshramaSevaSociety from "./DetailPages/Kashi/SreeKashiGayatriAshramaSevaSociety";
import ArunachalamKariveniSatram from './DetailPages/Arunachalam/KariVeniSatram';
import ListPage from './pages/ListPage';

// https://www.freecodecamp.org/news/how-to-use-react-router-version-6/ - good example for router
// https://medium.com/@ahsan-ali-mansoor/define-react-routes-with-better-approach-typescript-d07de782b517 - To implement lazy load and authentiation
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact /> } />
        <Route path='/annasatram' element={<AnnaSatram />} />
        <Route path='/details/vemulawada/sri-rajeshwara-charitable-trust' element={<VemulawadaRajeshwaraCharitableTrust />} />
        <Route path='/details/kashi/sree-kashi-gayatri-ashrama-seva-society' element={<SreeKashiGayatriAshramaSevaSociety />} />
        <Route path='/details/arunachalam/kariveni-satram' element={<ArunachalamKariveniSatram />} />
        <Route path='/list/:page_name' element={<ListPage />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

export default App;
