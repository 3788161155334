import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

const VemulawadaRajeshwaraCharitableTrust : React.FC = () =>{
    return(
        <>
            <br/><br/>
            <h1>Sri Rajeshwara Chariteble Trust (Vemulawada)</h1>
            <br />
            <Card>
                <Card.Body>
                    <Card.Title>Dharmika Bhavanamu / Annadana Satramu</Card.Title>
                    <Card.Text>
                        Beside Temple, Brahamana Veedhi, Vemulawada.
                    </Card.Text>
                </Card.Body>
                <ListGroup>
                    <ListGroup.Item>
                        - ఉచిత భోజనం. Roud the clock (24x7) Bhojanam is provided with 1/2 hr waiting time (Please allow 30 mins for preparation during non-regular times)
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <strong>Activities:</strong>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        - Surabhi Goshala
                    </ListGroup.Item>
                    <ListGroup.Item>
                        - వసతి లేని వారికి భోజన సదుపాయం
                    </ListGroup.Item>
                    <ListGroup.Item>
                        - కార్తీకమాసంలో సంవస్తర దీపోస్తవం (samvastara depostavam in karthikamasam)
                    </ListGroup.Item>
                </ListGroup>
                <Card.Body>
                    <Card.Link href="tel:8186956255">8186956255</Card.Link>
                </Card.Body>
            </Card>
        </>
    )
}

export default VemulawadaRajeshwaraCharitableTrust;