import React from "react";
import Card from 'react-bootstrap/Card';

const SreeKashiGayatriAshramaSevaSociety:React.FC = () =>{
    return(
        <>
            <br/><br/>
            <h1>Sree Kashi Gayatri Ashrama Seva Society</h1>
            <br />
            <Card style={{ width: '18rem' }}>
                <Card.Img variant='top' className="d-block mx-auto img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/gayatri-asrama-seva-society-kashi.jpeg`} />
            </Card>
            <Card>
                <Card.Body>
                    <strong>Contact: </strong>
                    <Card.Link href="tel:89191 23647">89191 23647</Card.Link> / 
                    <Card.Link href="tel:99367 64525">99367 64525</Card.Link> / 
                    <Card.Link href="tel:99187 74933">99187 74933</Card.Link> <br />
                    <strong>Email: </strong>
                    <Card.Link href="mailto:sethu2kasi@gmail.com">sethu2kasi@gmail.com</Card.Link><br />
                    <strong>Facebook: </strong>
                    <Card.Link href="https://www.facebook.com/kashi.hariharasastry" target="_blank">https://www.facebook.com/kashi.hariharasastry</Card.Link><br />

                    <Card.Text>
                        <strong> Address: </strong>  Sree Gayatri Seva Society, Sree Kashi Gayatri Asramam, Abburu Harihara Sastry, D 47 / 2B 2G, Near PDR mall, Ramapura, Varanasi - 221001 <br />
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    );
}

export default SreeKashiGayatriAshramaSevaSociety;