import React, {useState,useEffect} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

import myData from "../../data/anna_satram.json";
// https://www.dhiwise.com/post/dynamic-routing-for-building-flexible-and-scalable-react-apps#:~:text=Implementing%20Dynamic%20Routing%20in%20ReactJS,-Introduction%20to%20React&text=It%20provides%20a%20set%20of,seamless%20and%20interactive%20user%20experience.
type phoneNumbers = string[]
type emailAddress = string[]
type contactDetails = {
    phone: phoneNumbers,
    email?: emailAddress
}

type moreDetails = {
    url: string,
    isExternal: boolean
}

type annaSatram = {
    name: string,
    contact: contactDetails,
    location: string;
    more_details_link?: moreDetails
}


const AnnaSatram:React.FC = () => {

    function getPhone(phone: phoneNumbers){
       const phoneItems =  phone.map((phoneNumber, key)=><div key={"phone_"+key}><a href={"tel:"+phoneNumber}>{phoneNumber}</a><br /></div>)
        return(
            <td>
                {phoneItems}
            </td>
        )
    }

    function getName(item:annaSatram){
        const moreDetails = item.more_details_link;
        const name = moreDetails  ? moreDetails.isExternal ? <a href={moreDetails.url} target='_blank'>{item.name}</a> : <a href={moreDetails.url}>{item.name}</a> : <p>{item.name}</p>
        return(
            <td>{ name }</td>
        )
    }

    function getListItem(key:number, item:annaSatram){
        return (
            <tr key={"item_"+key}>
                <td>{key+1}</td>
                { getName(item) }
                { getPhone(item.contact.phone) }
                <td>{item.location}</td>
            </tr>
        )
    }

    return(
        <>
            <br/>
            <h1>Anna Satramulu</h1>
            <br/>
            <Card style={{ width: '18rem' }}>
                <Card.Img variant='top' className="d-block mx-auto img-fluid w-50" src={`${process.env.PUBLIC_URL}/assets/images/anna-satram-image2.jpeg`} />
            </Card>
            <br/>
            <h3>కొన్ని బ్రాహ్మణ నిత్యా అన్నా దాన సత్రాల వివరములు</h3>
            <Table responsive>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Location</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        myData && myData.length>0 && myData.map((item: annaSatram, key:number)=>getListItem(key, item))
                    }
                </tbody>
            </Table>
            <Table responsive>
                {/* <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Location</th>
                    </tr>
                </thead> */}
                <tbody>
                    {/* <tr>
                        <td>1</td>
                        <td>
                            <Link to="/details/arunachalam/kariveni-satram">Karivena Satram - meals and accomodation - Arunachalam</Link>
                        </td>
                        <td>
                            <a href="tel:+919487340111">+919487340111</a><br />
                            <a href="tel:+914175224060">+914175224060</a><br />
                            <a href="tel:+919390734802">+919390734802</a><br />
                            <a href="tel:+919704833439">+919704833439</a><br />
                            <a href="tel:+918333907813">+918333907813</a><br />
                        </td>
                        <td>
                            Arunachalam
                        </td>
                    </tr> */}
                    {/* <tr>
                        <td>2</td>
                        <td>
                            <Link to="/details/kashi/sree-kashi-gayatri-ashrama-seva-society">Sree Kashi Gayatri Ashrama Seva Society</Link>
                        </td>
                        <td>
                            <a href="tel:8919123647">8919123647</a><br />
                            <a href="tel:9936764525">9936764525</a><br />
                            <a href="tel:9918774933">9918774933</a><br />
                        </td>
                        <td>
                            Kashi
                        </td>
                    </tr> */}

                    <tr>
                        <td>3</td>
                        <td>
                            <Link target='_blank' to="https://www.facebook.com/gayatri.brahmana">Shree Uma Maheswara Shree Gayatri Brahmana Anna Satram </Link>
                            <br />
                            శ్రీ ఉమామహేశ్వర గాయత్రీ బ్రాహ్మణ నిత్యాన్నదాన సత్రం
                        </td>
                        <td><a href="tel:+919493610469">+919493610469</a></td>
                        <td>Yaganti <br /> యాగంటి</td>
                    </tr>

                    <tr>
                        <td>5</td>
                        <td>
                            <Link to="/details/vemulawada/sri-rajeshwara-charitable-trust"> Sri Rajeshwara Chariteble Trust</Link>
                        </td>
                        <td>
                            <a href="tel:+918186956255">+918186956255</a>
                        </td>
                        <td> 
                            Vemulawada<br />
                            వేములవాడ
                        </td>
                    </tr>

                    <tr>
                        <td>6</td>
                        <td>
                        Sri Raja Rajeshwara Nitya Anna Satram <br />
                        శ్రీ రాజ రాజేశ్వర బ్రాహ్మణ నిత్యన్న సత్రం
                        </td>
                        <td>
                            <a href="tel:+919347482602">+919347482602</a>
                        </td>
                        <td>
                            Vemulawada<br />
                            వేములవాడ
                        </td>
                    </tr>
                </tbody>
            </Table>
            <h3>Yet to verify:</h3>
                <ListGroup numbered>
                
                    {/* <ListGroup horizontal>
                        <ListGroup.Item variant="info"><a href="tel:srct@gmail.com">8186956255</a></ListGroup.Item>
                        <ListGroup.Item variant="info">
                            <Link to="/details/vemulawada/sri-rajeshwara-charitable-trust">More details...</Link>
                        </ListGroup.Item>
                    </ListGroup> */}
                    
                    
                    {/* <ListGroup.Item>Sri Rajarajeshwari Chariteble Trust (Vemulawada ) 
                        <ListGroup horizontal>
                            <ListGroup.Item variant="info"><strong>Email: </strong><a href="mailto:srrct@gmail.com">srrct@gmail.com</a></ListGroup.Item>
                            <ListGroup.Item variant="info"><strong>Website: </strong> <a href='http://www.srrct.org/' target='_blank'>http://www.srrct.org</a></ListGroup.Item>
                            <ListGroup.Item variant="info">Brahmana nity anna dhana satram / dharmika bhavanam / ac and nonac rooms vemulawada - 505302</ListGroup.Item>
                        </ListGroup>
                    </ListGroup.Item> */}
                    <ListGroup.Item>	౨	అన్నపూర్ణ బ్రాహ్మణ నిత్య అన్నసత్రము – యాదాద్రి (Yadadri) – 9849125769 </ListGroup.Item>
                    
                    <ListGroup.Item>	౪	యాదగిరి గుట్ట (Yadadri / Yadagirigutta) – 9502802468 </ListGroup.Item>
                    <ListGroup.Item>	౬	బాసర. (Basara) – 08752 255522</ListGroup.Item>
                    <ListGroup.Item>	౭	ధర్మపురి. (Dharmapuri) – 08724 274155</ListGroup.Item>
                    <ListGroup.Item>	౮	భద్రాచలం (Bhadrachalam) – 8333907796</ListGroup.Item>
                    <ListGroup.Item>	౯	శ్రీశైలం (srisailam) – 8333907814</ListGroup.Item>
                    <ListGroup.Item>	౧౦	వారణాసి ( కాశీ) (Varanasi – kashi) – 8333907790</ListGroup.Item>
                    <ListGroup.Item>	౧౧	రామేశ్వరం (Rameshwaram) – 8333907793</ListGroup.Item>
                    <ListGroup.Item>	౧౨	షిర్డి (Shiridi) – 8333907800</ListGroup.Item>
                    <ListGroup.Item>	౧౩	మహానంది (Mahanandi) – 8333907803</ListGroup.Item>
                    <ListGroup.Item>	౧౪	అమలాపురం (Amalapuram) – 8333907806</ListGroup.Item>
                    <ListGroup.Item>	౧౫	అరసవెల్లి (Arasavalli) – 08942 228835, 9848179755</ListGroup.Item>
                    <ListGroup.Item>	౧౬	విజయవాడ (Vijayawada) – 0866 2426292</ListGroup.Item>
                    <ListGroup.Item>	౧౭	మోపిదేవి (Mopidevi) – 08671 257900, 9390142245</ListGroup.Item>
                    <ListGroup.Item>		Anjaneya Brahmana Nitya Annadana Trust (Beechupally) – 08502-249335 </ListGroup.Item>
                </ListGroup>
                <br />
            
        </>
    );
}

export default AnnaSatram;